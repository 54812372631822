import { Controller } from "stimulus";

export default class extends Controller {
  imagePreviewContainer; //contains a jQuery elem for the image div container
  hiddenPhotoIdField; //contains a jQuery elem for the hidden photo_id field

  ///> constructor
  connect() {
    const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];
    let fileElems = document.querySelectorAll(
      "input[type='file'].cloudinary-fileupload"
    );

    fileElems.forEach((fileElem) => {
      ///> select file change event handler
      fileElem.onchange = (event) => {
        let $target = $(event.target);

        this.imagePreviewContainer = $target.prev();
        this.hiddenPhotoIdField = $target.next();

        const fileType = fileElem.files[0].type;

        if (!ALLOWED_IMAGE_TYPES.includes(fileType)) {
          alert(`Error: invalid image format [${fileType}]`);
          return;
        }

        const url = fileElem.getAttribute("data-url");
        let upload_data = JSON.parse(fileElem.getAttribute("data-form-data"));
        let reader = new FileReader();

        ///> finished reading selected file handler
        reader.onload = (event) => {
          let fileData = event.target.result;

          upload_data["file"] = fileData;
          // console.log(`
          //   FILE ELEM ATTR
          //   ---------------
          //   data-url: ${url}
          //   upload_data: ${JSON.stringify(upload_data)}
          // `);

          ///> remove image tag and replace with progress bar
          this.imagePreviewContainer.html(
            CustomTemplate.bootstrapProgressBar()
          );

          let req = $.ajax({
            xhr: () => {
              let _xhr = new window.XMLHttpRequest();
              _xhr.upload.addEventListener(
                "progress",
                (event) => {
                  if (event.lengthComputable) {
                    let percentCompleted = (event.loaded / event.total) * 100;
                    this.imagePreviewContainer
                      .find(".progress-bar")
                      .css("width", `${Math.round(percentCompleted)}%`);
                  }
                },
                false
              );
              return _xhr;
            },
            type: "POST",
            url: url,
            data: upload_data,
            dateType: "json",
          });

          req.done((data) => {
            if (data.public_id) {
              if (this.imagePreviewContainer && this.hiddenPhotoIdField) {
                ///> add image tag for preview
                this.imagePreviewContainer.empty();
                this.imagePreviewContainer.prepend(
                  `<img src="${data.secure_url}" alt="poster preview" />`
                );
                this.hiddenPhotoIdField.val(data.public_id);
              } else {
                console.error("no container field");
              }
            }
          });

          req.fail((xhr) => {
            let msgs = [];
            msgs.unshift(`${xhr.status} - ${xhr.statusText}`);

            $("#notification").html(
              CustomTemplate.displayFormMessages("danger", msgs)
            );

            console.log(`xhr req failed -> `, xhr.responseText);
          });
        };

        reader.readAsDataURL(fileElem.files[0]);
      };
    }); //forEach
  } //connected
}

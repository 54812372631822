import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  ///> constructor
  connect() {}

  addTerm(event) {
    event.preventDefault();
    const elem = this.contentTarget;

    $(".form-errors").empty(); //clear errors

    const percent = $("#percentage").val();
    const premiere = $("#premiere").prop("checked");
    const from_date = $("#effective_from").val();
    const to_date = $("#effective_to").val();

    let errors = '<div class="alert alert-danger"><ul class="list-unstyled">';
    let has_errors = false;
    if (percent == "" || isNaN(percent)) {
      errors += "<li>Percentage must be a number.</li>";
      has_errors = true;
    }
    if (from_date == "") {
      errors += "<li>Must enter Effective From date.</li>";
      has_errors = true;
    }
    if (to_date == "") {
      errors += "<li>Must enter Effective To date.</li>";
      has_errors = true;
    }
    errors += "</ul></div>";

    if (has_errors) {
      $(".form-errors").append(errors);
      return;
    } else {
      let row_id = $(".dyn-terms table tbody tr").length + 1;
      let d_from = from_date.match(/(\d+)/g);
      let d_to = to_date.match(/(\d+)/g);
      $(".dyn-terms").show();

      let display_premiere = premiere ? "Yes" : "No";
      let display_from_date = new Date(
        d_from[0],
        d_from[1] - 1,
        d_from[2]
      ).toDateString();
      let display_to_date = new Date(
        d_to[0],
        d_to[1] - 1,
        d_to[2]
      ).toDateString();
      let delete_link = `<a href="#" data-action="cinemas--movie-location#removeTerm" data-cinemas--movie-location-term-id-param="${row_id}"><span class="fas fa-trash"></span></a>`;

      elem.innerHTML += `<tr id="${row_id}">
        <td>${percent}%</td>
        <td>${display_premiere}</td>
        <td>${display_from_date}</td>
        <td>${display_to_date}</td>
        <td>${delete_link}</td>
      </tr>`;

      // add term to hidden form fields
      const formTermId = row_id - 1; // zero-based index

      $("form#new_movie_location").append(
        `<div id="hidden-${formTermId}">
          <input type="hidden" value="${percent}" name="movie_location[terms][${formTermId}][percentage]" />
          <input type="hidden" value="${premiere}" name="movie_location[terms][${formTermId}][premiere]" />
          <input type="hidden" value="${from_date}" name="movie_location[terms][${formTermId}][effective_from]" />
          <input type="hidden" value="${to_date}" name="movie_location[terms][${formTermId}][effective_to]" />
        </div>`
      );

      // clear fields
      $("#percentage").val("");
      $("#effective_from").val("");
      $("#effective_to").val("");
      $("#premiere").removeAttr("checked");
    } // else
  }

  removeTerm(event) {
    event.preventDefault();
    const id = event.params.termId;

    $(`.dyn-terms table tbody tr#${id}`).remove();
    $(`div#hidden-${parseInt(id) - 1}`).remove();
    if ($(".dyn-terms table tbody tr").length == 0) {
      $(".dyn-terms").hide();
    } else {
      // TODO: update ID's, decrement each one, in both display & hidden!!!
    }
  }
}

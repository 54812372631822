import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["title", "manageDataInfo", "saleData"];

  ///> constructor
  connect() {}

  updateSaleData(event) {
    this.titleTarget.innerHTML = event.detail.title;
    this.manageDataInfoTarget.innerHTML = event.detail.manageDataInfo;

    if (event.detail.shouldHideLoader) {
      $(".first-loader-animation").hide();
    }

    this.saleDataTarget.innerHTML = event.detail.saleData;
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["movieTitleId", "selectedFormat"];

  ///> constructor
  connect() {}

  addFormat() {
    const formatId = this.selectedFormatTarget.value;
    const movieTitleId = this.movieTitleIdTarget.value;
    const formatName = $("#format_select option:selected").text();

    if (!formatId) {
      return;
    }

    let $table = $("#format-listing tbody");
    const url = `/api/v1/movie_titles/${movieTitleId}/movie_formats`;

    let req = $.ajax({
      type: "POST",
      url: url,
      data: { movie_format_id: formatId },
      dateType: "json",
    });

    req.done((res) => {
      $table.append(CustomTemplate.addFormat(formatName, formatId));
      $(`#format_select option[value='${formatId}']`).remove();
    });

    req.fail((xhr) => {
      let msgs = [];
      msgs = CustomTemplate.extractActiveRecordErrors(xhr.responseJSON);
      msgs.unshift(`${xhr.status} - ${xhr.statusText}`);

      $("#notification").html(
        CustomTemplate.displayFormMessages("danger", msgs)
      );

      console.log(`ajax create failed -> `, xhr.responseText);
    });
  }

  deleteFormat(event) {
    event.preventDefault();
    const $button = $(event.currentTarget);
    const $selectFormat = $("#format_select");
    const formatId = $button.data("formatid");
    const formatName = $button.closest("td").prev("td").text();
    const movieTitleId = this.movieTitleIdTarget.value;
    const url = `/api/v1/movie_titles/${movieTitleId}/movie_formats/${formatId}`;

    let req = $.ajax({
      type: "DELETE",
      url: url,
      dateType: "json",
    });

    req.done((res) => {
      $button.closest("tr").remove();
      $selectFormat.append(
        $("<option>", {
          value: formatId,
          text: formatName,
        })
      );

      //sort select options
      let optionList = $("#format_select option");
      optionList.sort((a, b) => {
        a = a.value;
        b = b.value;

        return a - b;
      });
      $selectFormat.html(optionList);
    });

    req.fail((xhr) => {
      let msgs = [];
      msgs = CustomTemplate.extractActiveRecordErrors(xhr.responseJSON);
      msgs.unshift(`${xhr.status} - ${xhr.statusText}`);

      $("#notification").html(
        CustomTemplate.displayFormMessages("danger", msgs)
      );

      console.log(`ajax delete failed -> `, xhr.responseText);
    });
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["name"]

  // updates location name within html
  changeName(event) {
    const elemIdentifier = `[id='${event.params.locationId}'] td`;
    const elem = document.querySelector(elemIdentifier);

    if(elem === null) {
      console.error(`unable to find element: ${elemIdentifier}`);
    }

    elem.innerHTML = this.nameTarget.value;
  }
}
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  ///> constructor
  connect() {}

  tempusDateToIso8601(tempusDateObj) {
    if (!tempusDateObj) {
      return null;
    }

    const dateObj = tempusDateObj[0];
    return `${dateObj.year}-${dateObj.month}-${dateObj.date}`;
  }

  getMinAndMaxDates(momentDateRangeObj) {
    if (!momentDateRangeObj) {
      return null;
    }

    let dateRange = {}; // start => minDate, end => maxDate
    let minDate, maxDate;

    minDate = momentDateRangeObj[0].start.format();
    maxDate = momentDateRangeObj[momentDateRangeObj.length - 1].end.format();
    dateRange.start = minDate;
    dateRange.end = maxDate;

    return dateRange;
  }

  datePickerHandler(saleData, myPicker) {
    let dateText = myPicker.getDate();

    let searchData = {
      movie_location_id: saleData.movieLocationId,
      sale_date: moment(new Date(dateText)).format("YYYY-MM-DD"),
    };

    $("#modal-sale-data").empty();
    $("#movie-location-sale-data .second-loader-animation").show();

    $.post("/api/v1/find_ticket_sales", searchData, function (data) {
      let saleModel = _.extend(
        {
          sale_date: dateText,
          movie_location_id: saleData.movieLocationId,
        },
        data
      );

      saleModel.urlRoot = "/api/v1/daily_ticket_sales";
      saleModel.saleData = saleData;
      saleModel.isNew = saleModel.id ? false : true;
      const saleView = CustomTemplate.ticketSaleForm(saleModel);

      $("#movie-location-sale-data .second-loader-animation").hide();
      $("#modal-sale-data").html(saleView);
    }); // End of post
  }

  /**
   * Manage Sales Data - Edit Button Click Handler
   * @param {*} event - stimulus data
   */
  showEditForm(event) {
    const model = JSON.parse(decodeURIComponent(event.params.model));
    const elem = $("#movie-location-sale-data");

    if (model.usd_rate) {
      elem.find("input#usd_rate").val(model.usd_rate);
    }

    elem.find("input#head_count").val(model.head_count);
    elem.find("input#net_sale").val(model.net_sale);
    elem.find("#usd-rate").hide();
    elem.find("input#calc_local_currency").val(model.calc_local_currency);
    elem.find("input").prop("disabled", false);
    elem.find("#edit-form-btn").hide();
    elem.find("#cancel-edit-form-btn").show();
    elem.find("#save-form-btn").show();
  }

  /**
   * Manage Sales Data - Cancel Button Click Handler
   */
  cancelEditForm() {
    const elem = $("#movie-location-sale-data");
    $("#modal-sale-data .form-errors").empty();

    elem.find("#usd-rate").show();
    elem.find("input").prop("disabled", true);
    elem.find("#edit-form-btn").show();
    elem.find("#cancel-edit-form-btn").hide();
    elem.find("#save-form-btn").hide();
  }

  /**
   * Manage Sales Data - Create Sale Button Click Handler
   * @param {*} event - stimulus data
   */
  createSale(event) {
    const model = JSON.parse(decodeURIComponent(event.params.model));
    const elem = $("#movie-location-sale-data");
    let form = {};

    form.movie_location_id = model.movie_location_id;
    form.sale_date = moment(new Date(model.sale_date)).format("YYYY-MM-DD");

    if (model.usd_rate) {
      form.usd_rate = elem.find("input#usd_rate").val();
    } else {
      form.usd_rate = model.saleData.usdRate;
    }

    if (model.calc_local_currency) {
      form.calc_local_currency = elem.find("input#calc_local_currency").val();
    }

    if (model.saleData.screensRequired) {
      form.no_of_screens = elem.find("input#no_of_screens").val();
    }

    form.verified = elem.find("input#verified").is(":checked");
    form.showed = elem.find("input#showed").is(":checked");
    form.head_count = parseInt(elem.find("input#head_count").val(), 10);
    form.net_sale = elem.find("input#net_sale").val();

    const createForm = { daily_ticket_sale: form };
    const url = `/api/v1/daily_ticket_sales`;

    let req = $.ajax({
      type: "POST",
      url: url,
      data: createForm,
      dateType: "json",
    });

    req.done((res) => {
      $("#modal-sale-data .form-errors").html(
        CustomTemplate.displayFormMessages("success", [`Ticket Sale Created`])
      );

      elem.find("input").prop("disabled", true);
      elem.find("#edit-form-btn").show();
      elem.find("#cancel-edit-form-btn").hide();
      elem.find("#save-form-btn").hide();
    });

    req.fail((xhr) => {
      let msgs = [];
      msgs = CustomTemplate.extractActiveRecordErrors(xhr.responseJSON);
      msgs.unshift(`${xhr.status} - ${xhr.statusText}`);

      $("#modal-sale-data .form-errors").html(
        CustomTemplate.displayFormMessages("danger", msgs)
      );

      console.log(`ajax create failed -> `, xhr.responseText);
    });
  }

  /**
   * Manage Sales Data - Save Sale Button Click Handler
   * @param {*} event - stimulus data
   */
  saveSale(event) {
    const model = JSON.parse(decodeURIComponent(event.params.model));
    const elem = $("#movie-location-sale-data");
    let form = {};

    form.movie_location_id = model.movie_location_id;
    form.sale_date = model.sale_date;

    if (model.usd_rate) {
      form.usd_rate = elem.find("input#usd_rate").val();
    }

    if (model.calc_local_currency) {
      form.calc_local_currency = elem.find("input#calc_local_currency").val();
    }

    if (model.saleData.screensRequired) {
      form.no_of_screens = elem.find("input#no_of_screens").val();
    }

    form.verified = elem.find("input#verified").is(":checked");
    form.showed = elem.find("input#showed").is(":checked");
    form.head_count = parseInt(elem.find("input#head_count").val(), 10);
    form.net_sale = elem.find("input#net_sale").val();

    const updateForm = { daily_ticket_sale: form };
    const url = `/api/v1/daily_ticket_sales/${model.id}`; // daily ticket sale ID

    let req = $.ajax({
      type: "PUT",
      url: url,
      data: updateForm,
      dateType: "json",
    });

    req.done((res) => {
      $("#modal-sale-data .form-errors").html(
        CustomTemplate.displayFormMessages("success", [
          `Ticket Sale Update was Successful`,
        ])
      );

      elem.find("input").prop("disabled", true);
      elem.find("#edit-form-btn").show();
      elem.find("#cancel-edit-form-btn").hide();
      elem.find("#save-form-btn").hide();
    });

    req.fail((xhr) => {
      $("#modal-sale-data .form-errors").html(
        CustomTemplate.displayFormMessages("danger", [
          `${xhr.status} - ${xhr.statusText}`,
        ])
      );

      console.log(`ajax update failed -> `, xhr.responseText);
    });
  }

  /**
   * Manage Sales Data OnClick Handler
   * @param {*} event - stimulus data
   */
  loadSalesData(event) {
    event.preventDefault();

    // clear anything previously
    $("#movie-location-sale-data .manage-data").empty();
    $("#modal-sale-data").empty();

    const self = this;
    let modalData = {
      detail: {},
    };

    let saleData = {
      movieTitle: event.params.movietitle,
      movieFormat: event.params.movieformat,
      cinema: event.params.cinema,
      location: event.params.location,
      country: event.params.country,
      movieLocationId: event.params.movielocation,
      screensRequired: event.params.screensrequired,
      usAndLocalCurrencyRequired: event.params.usandlocalcurrencyrequired,
      usdRate: event.params.usdrate,
    };

    let url = `/api/v1/movie_locations/${saleData.movieLocationId}/term_percentages`;

    let dateRanges; //moment date range

    // make json request to get term_percentages so i know what dates are
    // available to enter data in.
    $.getJSON(url, function (data) {
      dateRanges = _.map(data, function (model) {
        return moment.range(model.effective_from, model.effective_to);
      });

      // Hide loader gif
      $("#movie-location-sale-data .first-loader-animation").hide();

      const dateRange = self.getMinAndMaxDates(dateRanges);
      const datePickerDiv = document.getElementById(
        "modal-sale-data-datepicker"
      );

      const dateFormat = "YYYY-MM-DD";

      datePickerDiv.innerHTML = ""; // clears any previously bound datepicker
      const myPicker = new easepick.create({
        element: datePickerDiv,
        css: [
          "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css",
          "https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.1/dist/index.css",
        ],
        format: dateFormat,
        inline: true,
        zIndex: 1000,
        date: dateRange.start,
        autoApply: true,
        AmpPlugin: {
          dropdown: {
            years: true,
            minYear: null,
            maxYear: null,
            months: true,
          },
          darkMode: false,
        },
        LockPlugin: {
          minDate: dateRange.start,
          maxDate: dateRange.end,
        },
        plugins: [AmpPlugin, LockPlugin],
      });

      myPicker.show();
      self.datePickerHandler(saleData, myPicker); // initial load execution

      // keep picker open
      myPicker.on("render", (e) => {
        myPicker.show();
      });

      // refresh data screen on new date selection
      myPicker.on("select", (e) => {
        self.datePickerHandler(saleData, myPicker);
      });

      modalData.detail.title = "Manage Sales Data";
      modalData.detail.manageDataInfo = `${saleData.cinema}<br/>${saleData.location} - ${saleData.country}<br />${saleData.movieTitle} ${saleData.movieFormat}`;
      modalData.detail.shouldHideLoader = true;
      modalData.detail.saleData = "";

      const updateModalEvent = new CustomEvent("update-modal", modalData);
      window.dispatchEvent(updateModalEvent);
    }); // end json success function

    $("#movie-location-sale-data").on("hidden.bs.modal", function (event) {
      $("#movie-location-sale-data .modal-title").html(
        "Loading Ticket Sale Data"
      );

      $("#movie-location-sale-data .manage-data").empty();
      $("#modal-sale-data").off();
      $("#modal-sale-data").empty();
      $("#movie-location-sale-data .first-loader-animation").show();
    }); // end of modal hidden
  }
}
